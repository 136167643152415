<template>
<v-card class="mx-auto" min-width="480" max-width="480" min-height="310" outlined :loading="loading">
    <template slot="progress">
      <v-progress-linear color="deep-purple" height="3" indeterminate></v-progress-linear>
    </template>
    <v-list-item three-line >
      <v-list-item-content>
        <div class="text-overline text-center">{{labelCategoria}}</div>
        <v-list-item-subtitle style="min-height: 32px;">
          <v-row no-gutters>
            <v-col><v-switch v-if="labelSwitchPeriodo" v-model="ultimoPeriodo" :label="labelSwitchPeriodo" class="ml-3" dense @change="onAggiorna"></v-switch></v-col>
            <v-col><v-select v-if="sceltaPeriodo" :items="tipiPeriodo" v-model="periodo" label="Periodo" class="pt-3" dense @change="onAggiorna"></v-select></v-col>
          </v-row>
        </v-list-item-subtitle>
        <v-list-item-title>
        </v-list-item-title>
        <v-list-item-content>
          <apexchart width="450" :type="item.tipo" :options="chartOptions" :series="seriesQ"></apexchart>
        </v-list-item-content>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions class="d-flex justify-space-around">
      <v-badge bordered color="error" icon="mdi-timer-outline" overlap :value="timerOn">
        <v-btn outlined text @click="onAggiorna">Aggiorna<v-icon right>mdi-refresh</v-icon></v-btn>
      </v-badge>
      <v-btn outlined text @click="onEspandi">{{ espanso ? 'Contrai' : 'Espandi' }}<v-icon right>mdi-unfold-more-horizontal</v-icon></v-btn>
    </v-card-actions>
    <v-card-text v-if="sceltaRaggruppamento" class="pt-0 pb-0">  
      <v-divider class="my-4"></v-divider>
      <v-select :items="tipiRaggruppamento" v-model="raggruppamento" label="Raggruppamento" dense @change="onAggiorna"></v-select>
    </v-card-text>
  </v-card>    
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import collector from '@/services/analisi/collector'

export default {
  components: {
  },
  props: {
    item: Object,
    base: String,
    apikey: String
  },
  data() {
    return {
      filter: null,
      espanso: false,
      loading: false,
      dati: [],
      sum: {},
      ultimoPeriodo: true,
      raggruppamento: 'Fascia Oraria',
      tipiPeriodo: ['giorno', 'settimana', 'mese', 'anno'],
      tipiPeriodoCalcolo: ['day', 'week', 'month', 'year'],
      periodo: 'settimana',    
      switchLabelPeriodo: ['', 'ultimi 7 giorni', 'ultimi 30 giorni', 'ultimi 12 mesi'],
      timerOn: false,
      intervalloAggiornamentoMinuti: 5,
      aggregati: [],
      series:[]
    }
  },
  async mounted() {
    await this.onAggiorna()
  },
  watch: {
    periodo(value) {
      if (['giorno', 'settimana', 'mese'].includes(this.periodo)) {
        if (!this.intervalAggiornamento) {
          this.intervalAggiornamento = setInterval(() => {
            this.onAggiorna()
          }, this.intervalloAggiornamentoMinuti * 60 * 1000)
          this.timerOn = true
        }
      } else {
        if (this.intervalAggiornamento) {
          clearInterval(this.intervalAggiornamento)
          this.intervalAggiornamento = null
          this.timerOn = false
        }
      }
    }
  },  
  computed: {
    // *******************************************************************************
    // *******************************************************************************
    // *******************************************************************************    
    id() { return this.item && this.item.id },
    categoria() { return this.item && this.item.categoria },
    tipo() { return this.item && this.item.tipo },
    label() { return (this.item && this.item.label) || '' },
    switchLabel() { return this.item && this.item.switchLabel },
    descrizione() { return this.item && this.item.descrizione },
    tipoDashboard() { return this.item && this.item.tipoDashboard },
    avviaTimer() { return (this.item && this.item.avviaTimer) || false },
    lastElement() { return (this.item && this.item.lastElement) || false },
    sceltaRaggruppamento() { return (this.item && this.item.sceltaRaggruppamento) || false },
    sceltaPeriodo() { return (this.item && this.item.sceltaPeriodo) || true },
    tipiRaggruppamento() { return (this.item && this.item.tipiRaggruppamento) || [] },
    tipiRaggruppamentoVariabili() { return (this.item && this.item.tipiRaggruppamentoVariabili) || [] },
    // *******************************************************************************
    // *******************************************************************************
    // *******************************************************************************    
    labelSwitchPeriodo() {
      if (this.sceltaPeriodo) {
        const idx = this.tipiPeriodo.indexOf(this.periodo)
        return this.switchLabelPeriodo[idx]
      } else {
        return this.switchLabel
      }
    },
    labelCategoria() {
      if (this.sceltaRaggruppamento) {
        return 'Venduto per ' + this.raggruppamento
      } else {
        return this.categoria
      }
    },
    seriesQ() {
      return this.aggregati.map(x => x.q)
    },
    seriesV() {
      return this.aggregati.map(x => x.v)
    },
    seriesLabels() {
      return this.aggregati ? this.aggregati.map(x => x.k) : []
    },
/*     seriesOptions() {
      const opt = this.chartOptions
      Object.assign(opt.labels, this.seriesLabels)
      return opt
    }, */
    chartOptions() {
      return {
        chart: {
          width: 390,
          type: 'pie'
        },
        labels: this.seriesLabels
      }
    }
  },
  methods: {
    inizio(tipo) {
      if (!this.ultimoPeriodo) {
        return moment().startOf(tipo).format('YYYYMMDDHHmmss')
      } else {
        let offset = 7
        if (tipo === 'day') offset = 0
        if (tipo === 'month') offset = 30
        if (tipo === 'year') offset = 365
        return moment().add(-offset, 'day').startOf(tipo).format('YYYYMMDDHHmmss')
      }
    },
    fine(tipo) {
      if (!this.ultimoPeriodo) {
        return moment().endOf(tipo).format('YYYYMMDDHHmmss')
      } else {
        return moment().endOf('day').format('YYYYMMDDHHmmss')
      }
    },    
    onEspandi() {
      this.espanso = !this.espanso
    },
    async onAggiorna() {
      this.loading = true
      const idx = this.tipiPeriodo.indexOf(this.periodo)
      const periodoCalcolo = this.tipiPeriodoCalcolo[idx]
      const tipo = this.sceltaPeriodo ? periodoCalcolo : this.tipo
      let filtro = {
        start: this.inizio(tipo),
        end: this.fine(tipo)
      }

      // per debug, poi togliere
      filtro = { start: moment().add(-20, 'y').format('yyyyMMDD'), end: moment().format('yyyyMMDD')}

      const movimenti = await collector.getMovimentiReport(this.base, this.apikey, filtro)
      var that = this
      const tipi = this.tipiRaggruppamento 
      const rg = this.raggruppamento
      let rgIdx = tipi.findIndex(x => x === rg)
      let ragg = this.tipiRaggruppamentoVariabili[rgIdx]
      const rp = ragg ? '' : tipo
      const risultato = _.reduce(movimenti, function(result, value, key, collection) {
        let chiave = value[ragg]
        let prec = result[chiave] || { k: chiave, q: 0, v: 0 }
        prec.q += value.QtaMag
        prec.v = +(prec.v + value.PrezzoVendita).toFixed(2)
        result[chiave] = prec 
        return result
      }, {})
      if (risultato) {
        this.aggregati = Object.values(risultato)
      }      
      this.loading = false
    }
  }
}
</script>
