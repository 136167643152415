<template>
<v-card class="mx-auto" min-width="480" max-width="480" min-height="310" outlined :loading="loading">
    <template slot="progress">
      <v-progress-linear color="deep-purple" height="3" indeterminate></v-progress-linear>
    </template>
    <v-list-item three-line >
      <v-list-item-content>
        <div class="text-overline text-center">{{labelCategoria}}</div>
        <v-list-item-subtitle style="min-height: 32px;">
          <v-row no-gutters>
            <v-col><v-switch v-if="labelSwitchPeriodo" v-model="ultimoPeriodo" :label="labelSwitchPeriodo" class="ml-3" dense @change="onAggiorna"></v-switch></v-col>
            <v-col><v-select v-if="sceltaPeriodo" :items="tipiPeriodo" v-model="periodo" label="Periodo" class="pt-3" dense @change="onAggiorna"></v-select></v-col>
          </v-row>
        </v-list-item-subtitle>
        <v-list-item-title>
        </v-list-item-title>
<!--         <v-list-item-content>
           <v-data-table
              dense
              :headers="headers"
              :items="dati"
              item-key="_rev"
              class="elevation-1"
            ></v-data-table>
        </v-list-item-content>   -->
        <v-list-item-content>
          <v-simple-table dense fixed-header :height="espanso ? '' : '300px'" style="min-height: 300px" class="text-body2">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">data</th>
                  <th class="text-left w4">fascia oraria</th>
                  <th class="text-left">descrizione</th>
                  <th class="text-right">quantità</th>
                  <th class="text-right">valore</th>
                  <th class="text-left">causale</th>
                  <th class="text-left">categoria</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in dati" :key="item._rev">
                  <td class="text-left">{{ item.DatMag | date }}</td>
                  <td class="text-left w4">{{ item.FasciaOraria }}</td>
                  <td class="text-left">{{ item.Descrizione }}</td>
                  <td class="text-right">{{ item.QtaMag }}</td>
                  <td class="text-right">{{ item.PrezzoVendita }}</td>
                  <td class="text-left">{{ item.CauMag }}</td>
                  <td class="text-left">{{ item.Categoria1 }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td class="text-left">Totale</td>
                  <td class="text-left"></td>
                  <td class="text-left"></td>
                  <td class="text-right"></td>
                  <td class="text-right"></td>
                  <td class="text-left"></td>
                  <td class="text-left"></td>
                </tr>
              </tfoot>              
            </template>
          </v-simple-table>
        </v-list-item-content>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions class="d-flex justify-space-around">
      <v-badge bordered color="error" icon="mdi-timer-outline" overlap :value="timerOn">
        <v-btn outlined text @click="onAggiorna">Aggiorna<v-icon right>mdi-refresh</v-icon></v-btn>
      </v-badge>
      <v-btn outlined text @click="onEspandi">{{ espanso ? 'Contrai' : 'Espandi' }}<v-icon right>mdi-unfold-more-horizontal</v-icon></v-btn>
    </v-card-actions>   
  </v-card>    
</template>

<script>
import moment from 'moment'
import collector from '@/services/analisi/collector'

export default {
  components: {
  },
  props: {
    item: Object,
    base: String,
    apikey: String
  },
  data() {
    return {
      filter: null,
      espanso: false,
      loading: false,
      dati: [],
      sum: {},
      ultimoPeriodo: true,
      raggruppamento: '',
      tipiPeriodo: ['giorno', 'settimana', 'mese', 'anno'],
      tipiPeriodoCalcolo: ['day', 'week', 'month', 'year'],
      periodo: 'settimana',    
      switchLabelPeriodo: ['', 'ultimi 7 giorni', 'ultimi 30 giorni', 'ultimi 12 mesi'],
      timerOn: false,
      intervalloAggiornamentoMinuti: 5
/*       headers: [
        {
          text: 'Data',
          align: 'start',
          sortable: false,
          value: 'DatMag'
        },
        { text: 'Fascia oraria', value: 'FasciaOraria' },
        { text: 'Descrizione', value: 'Descrizione' },
        { text: 'Quantità', value: 'QtaMag' },
        { text: 'Valore', value: 'PrezzoVendita' },
        { text: 'Causale', value: 'CauMag' },
        { text: 'Categoria', value: 'Categoria1' }
      ] */
    }
  },
  async mounted() {
    await this.onAggiorna()
  },
  watch: {
    periodo(value) {
      if (['giorno', 'settimana', 'mese'].includes(this.periodo)) {
        if (!this.intervalAggiornamento) {
          this.intervalAggiornamento = setInterval(() => {
            this.onAggiorna()
          }, this.intervalloAggiornamentoMinuti * 60 * 1000)
          this.timerOn = true
        }
      } else {
        if (this.intervalAggiornamento) {
          clearInterval(this.intervalAggiornamento)
          this.intervalAggiornamento = null
          this.timerOn = false
        }
      }
    }
  },  
  computed: {
    // *******************************************************************************
    // *******************************************************************************
    // *******************************************************************************    
    id() { return this.item && this.item.id },
    categoria() { return this.item && this.item.categoria },
    tipo() { return this.item && this.item.tipo },
    label() { return (this.item && this.item.label) || '' },
    switchLabel() { return this.item && this.item.switchLabel },
    descrizione() { return this.item && this.item.descrizione },
    tipoDashboard() { return this.item && this.item.tipoDashboard },
    avviaTimer() { return (this.item && this.item.avviaTimer) || false },
    lastElement() { return (this.item && this.item.lastElement) || false },
    sceltaRaggruppamento() { return (this.item && this.item.sceltaRaggruppamento) || false },
    sceltaPeriodo() { return (this.item && this.item.sceltaPeriodo) || true },
    tipiRaggruppamento() { return (this.item && this.item.tipiRaggruppamento) || [] },
    tipiRaggruppamentoVariabili() { return (this.item && this.item.tipiRaggruppamentoVariabili) || [] },
    // *******************************************************************************
    // *******************************************************************************
    // *******************************************************************************    
    labelSwitchPeriodo() {
      if (this.sceltaPeriodo) {
        const idx = this.tipiPeriodo.indexOf(this.periodo)
        return this.switchLabelPeriodo[idx]
      } else {
        return this.switchLabel
      }
    },
    labelCategoria() {
      if (this.sceltaRaggruppamento) {
        return 'Venduto per ' + this.raggruppamento
      } else {
        return this.categoria
      }
    }    
  },
  methods: {
    inizio(tipo) {
      if (!this.ultimoPeriodo) {
        return moment().startOf(tipo).format('YYYYMMDDHHmmss')
      } else {
        let offset = 7
        if (tipo === 'day') offset = 0
        if (tipo === 'month') offset = 30
        if (tipo === 'year') offset = 365
        return moment().add(-offset, 'day').startOf(tipo).format('YYYYMMDDHHmmss')
      }
    },
    fine(tipo) {
      if (!this.ultimoPeriodo) {
        return moment().endOf(tipo).format('YYYYMMDDHHmmss')
      } else {
        return moment().endOf('day').format('YYYYMMDDHHmmss')
      }
    },    
    onEspandi() {
      this.espanso = !this.espanso
    },
    async onAggiorna() {
      this.loading = true
      const idx = this.tipiPeriodo.indexOf(this.periodo)
      const periodoCalcolo = this.tipiPeriodoCalcolo[idx]
      const tipo = this.sceltaPeriodo ? periodoCalcolo : this.tipo
      let filtro = {
        start: this.inizio(tipo),
        end: this.fine(tipo)
      }

      // TODO: per debug, poi togliere la riga quando ci sono i dati corretti nel database
      filtro = { start: moment().add(-20, 'y').format('yyyyMMDD'), end: moment().format('yyyyMMDD')}

      const movimenti = await collector.getMovimentiReport(this.base, this.apikey, filtro)
      
      // TODO: Completare. Versione con Map Reduce
      //       Va gestito il raggruppamento: 
      // 1) La map/reduce somma i movimenti per un dato raggruppamento su data / data + fascia oraria
      // 2) Ritornare l'informazione raggruppata in base al modello scelto --> per giorno, per mese, etc.
     //  const movimenti1 = await collector.getMovimentiMapReduceCategoria(this.base, this.apikey, filtro, 'P')

      this.dati = movimenti
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
  th, td {
    width: 15%;
    &.w1 {
      width: 10%;
    }
    &.w2 {
      width: 20%;
    }
    &.w3 {
      width: 30%;
    } 
    &.w4 {
      width: 40%;
    }        
  }
</style>